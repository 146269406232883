<template>
    <div class="mobile_Report">
        <div class="PersonalInformation flex-column-center">
            <div class="Report_title">{{ userInfo.userName }}同学{{ reportName }}报告</div>
            <div class="semester">学期：{{ $route.query.termKeyName }} 班级：{{ userInfo.className }} </div>
        </div>
        <div class="mainbody">
            <div class="examLevel">
                <div class="Level_title">CCPT书画等级考试推荐：<span style="color: #FF9900;">{{ ccptLevel }}</span>
                </div>
                <div class="Level_content">
                    <div class="Level_img flex-column-center">
                        <div class="Level_text">{{ $route.query.termKeyName }}{{ reportList[$route.query.reportValue]
                        }}：<span>{{ status[userComment.integrationLevel] }}</span></div>
                        <div class="Level_nationwide_Ranking">恭喜你：已经打败全国{{ rankNumber }}%的同学!!!</div>
                    </div>
                    <div class="Level_decoration"></div>
                </div>
                <div class="eachDimension">
                    <table class="styled-table">
                        <tbody>
                            <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
                                <td v-for="(cell, colIndex) in row" :key="colIndex">{{ cell.text }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="imageText" v-for="(item, index) in wordList" :key="index">
                    <div class="imageTable flex-align-center">
                        <div class="orderStrokes_image">
                            <img src="@/assets/assistant/tian.png" />
                            <img :src="item.wordShadowCover" />
                            <img :src="item.resFilename" />
                            <img :src="item.wordSkillCover" />
                        </div>
                        <div class="orderStrokes_table">
                            <div class="grid-item">居中占位: {{ item.centerPos ? computeLevel(item.centerPos) : '无' }}</div>
                            <div class="grid-item">占格大小: {{ item.gridSize ? computeLevel(item.gridSize) : '无' }}</div>
                            <div class="grid-item">字形重心: {{ item.glyphGrav ? computeLevel(item.glyphGrav) : '无' }}</div>
                            <div class="grid-item">收放关系: {{ item.rewindRel ? computeLevel(item.rewindRel) : '无' }}</div>
                            <div class="grid-item">多画等距: {{ item.multiDist ? computeLevel(item.multiDist) : '无' }}</div>
                            <div class="grid-item">笔画关系: {{ item.strokeRel ? computeLevel(item.strokeRel) : '无' }}</div>
                            <div class="grid-item">组合关系: {{ item.comboRel ? computeLevel(item.comboRel) : '无' }}</div>
                            <div class="grid-item">居中占位: {{ item.centerPos ? computeLevel(item.centerPos) : '无' }}</div>
                        </div>
                    </div>
                    <div class="Divider"></div>
                    <div class="Comment">
                        <div class="Comment_title">
                            <div class="Comment_title_text">点评</div>
                            <div class="Comment_title_decoration"></div>
                        </div>
                        <div class="Comment_content">
                            <ul>
                                <li v-for="(itemSon, indexSon) in  JSON.parse(item.remark)" :key="indexSon">
                                    <!-- @click="preview(itemSon.urls, 'multiple')" -->
                                    <!-- :class="{ ImgHighlight: itemSon.urls }" -->
                                    <span>{{ indexSon + 1 + '、' }}{{ itemSon.sug}}</span>
                                    <!-- @click="showImagePreview(item.urls)" -->
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="Preview_legend">
                    <div class="Comment">
                        <div class="Comment_title">
                            <div class="Comment_title_text">查看试卷</div>
                            <div class="Comment_title_decoration"></div>
                        </div>
                        <div class="Comment_content Comment_content_url">
                            <ul>
                                <li v-for="(item, index) in  workList" :key="index">
                                    <span @click="preview(item, 'single')">{{ '试卷' + (index + 1) }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layer flex-align-center" v-if="dialogVisible">
            <div class="slider_box ">
                <slider ref="slider" :options="options" @slide='slide' @tap='onTap' @init='onInit'>
                    <slideritem v-for="(item, index) in someList" :key="index" :style="item.style">
                        <img class="Previewimg" :src="item" alt="">
                    </slideritem>
                    <div slot="loading">loading...</div>
                </slider>
            </div>
            <div class="icon">
                <img src="../../assets/live/close.png" alt="" @click="close">
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { slider, slideritem } from 'vue-concise-slider'
export default {
    data() {
        return {
            tableData: [
                [
                    {
                        type: 'name',
                        text: "作品完整度"
                    },
                    {
                        type: 'name',
                        text: "居中占位"
                    },
                    {
                        type: 'name',
                        text: "占格大小"
                    },
                    {
                        type: 'name',
                        text: "宇形重心"
                    }
                ],
                [
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    }
                ],
                [
                    {
                        type: 'name',
                        text: "组合关系"
                    },
                    {
                        type: 'name',
                        text: "收放关系"
                    },
                    {
                        type: 'name',
                        text: "多画等距"
                    },
                    {
                        type: 'name',
                        text: "笔画关系"
                    }
                ],
                [
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    }
                ],
            ],
            dialogVisible: false,
            someList: [],
            options: {
                pagination: true,
                thresholdDistance: 100,
                thresholdTime: 300,
                grabCursor: true,
                speed: 300
            },
            userInfo: {
                userId: '',
                userName: '',
                schoolName: '',
                className: '',
            },
            reportName: '',
            status: {
                '4': '优秀',
                '3': '良好',
                '2': '合格',
                '1': '待提升'
            },
            userComment: '',
            reportList: {
                1: '前测',
                2: '后测',
                4: '月测',
                5: '周测',
            },
            rankNumber: '',
            ccptLevel: '',
            wordList: [],
            integrity: '',
            workList: [],
        }
    },
    components: {
        slider,
        slideritem
    },
    methods: {
        async getStuData() {
            let data = {
                termKey: this.$route.query.termKey, // 20232,
                userId: this.$route.query.userId, // 390990,
                examType: this.$route.query.reportValue, // 1前测 2后测 4月测
                testId: this.$route.query.monthValue,
            }
            let resData = await this.$Api.Report.getindRreport(data);
            this.userInfo = resData;
            this.reportName = resData.testName;
            this.userComment = resData.userComment;
            this.rankNumber = resData.rankNumber;
            this.ccptLevel = resData.ccptLevel;
            console.log(' this.ccptLevel', this.ccptLevel);

            this.integrity = resData.userComment.comment;
            this.tableData = [
                [
                    {
                        type: 'name',
                        text: "作品完整度"
                    },
                    {
                        type: 'name',
                        text: "居中占位"
                    },
                    {
                        type: 'name',
                        text: "占格大小"
                    },
                    {
                        type: 'name',
                        text: "宇形重心"
                    }
                ],
                [
                    {
                        type: 'grade',
                        text: this.computeLevel(resData.userComment.integrity),
                    },
                    {
                        type: 'grade',
                        text: this.computeLevel(resData.userComment.centerPos),
                    },
                    {
                        type: 'grade',
                        text: this.computeLevel(resData.userComment.gridSize),
                    },
                    {
                        type: 'grade',
                        text: this.computeLevel(resData.userComment.glyphGrav),
                    }
                ],
                [
                    {
                        type: 'name',
                        text: "组合关系"
                    },
                    {
                        type: 'name',
                        text: "收放关系"
                    },
                    {
                        type: 'name',
                        text: "多画等距"
                    },
                    {
                        type: 'name',
                        text: "笔画关系"
                    }
                ],
                [
                    {
                        type: 'grade',
                        text: this.computeLevel(resData.userComment.comboRel),
                    },
                    {
                        type: 'grade',
                        text: this.computeLevel(resData.userComment.rewindRel),
                    },
                    {
                        type: 'grade',
                        text: this.computeLevel(resData.userComment.multiDist),
                    },
                    {
                        type: 'grade',
                        text: this.computeLevel(resData.userComment.strokeRel),
                    }
                ],
            ];
            this.workList = resData.imgList;
            this.wordList = resData.wordList;
        },
        computeLevel(score) {
            if (score >= 85) {
                return '优秀'
            }
            if (score >= 70 && score < 85) {
                return '良好'
            }
            if (score >= 60 && score < 70) {
                return '合格'
            }
            if (score < 60) {
                return '待提升'
            }
        },
        preview(url, type) {
            console.log('url', url);
            if (type == 'single') {
                this.someList = [url];
            } else {
                this.someList = url;
            }
            // this.someList = this.imges;
            this.dialogVisible = true;
            document.body.style.overflow = 'hidden';
        },
        close() {
            this.dialogVisible = false;
            document.body.style.overflow = '';
        },
        slide() {

        },
        onTap() {

        },
        onInit() {

        }
    },
    mounted() {
        this.getStuData();

        // this.currentTime = moment().format("YYYY.MM.DD");  
    }
}
</script>
<style lang="less" scoped>
@import url('./less/new_report_stu_zhushou_move.less');

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.block {
    width: 120px;
    height: 120px;
    background-color: #fff;
}
</style>
